import React, { useState } from 'react';
import styled from 'styled-components';

const ByteColourMap: Record<string, string> = {
  'p': '#bceded',
  'u': '#fbc6cf',
  '-': '#e2ebc2'
}

const S = {
  Wrapper: styled.div`
    font-family: "San Francisco Mono","Monaco","Consolas","Lucida Console","DejaVu Sans Mono","Bitstream Vera Sans Mono",monospace;
    margin-block: 2rem;

    @media screen and (min-width: 768px) {
      width: 80vw;
      margin-left: -10vw;
    }
  `,

  Blocks: styled.div`
    margin-block: 2rem;
    display: flex;
    flex-wrap: wrap;
  `,

  Block: styled.div<{$index: number}>`
    display: flex;
    position: relative;
    justify-content: space-around;
    font-size: 0.8em;
    padding-top: 18px;
    border-right: 1px dashed gray;
    width: 100%;

    @media screen and (min-width: 768px) {
      width: calc(100% / 2);
    }

    &::after {
      position: absolute;
      top: 2px;
      right: 4px;
      content: 'block ${props => props.$index + 1}';
    }
  `,

  Byte: styled.div<{$type: string, $index: number}>`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-right: 1px dashed #999;
    background: ${props => ByteColourMap[props.$type]};
    padding: 2px;
    min-height: 30px;

    &:last-child {
      border-right: 0;
    }

    &::after {
      position: absolute;
      top: 2px;
      left: 2px;
      content: '${props => props.$index + 1}';
      font-size: 0.9em;
      color: #787878;
    }
  `,

  Legend: styled.div`
    font-size: 0.76em;
  `
}

export function ByteByByteECB() {
  const blockSize = 16;
  const unknownLength = 138;
  const [input, setInput] = useState(0);

  function getBytes(): string[][] {
    const inputBytes = new Array(input).fill('-');
    const unknownBytes = new Array(unknownLength).fill('u');

    const bytes = [...inputBytes, ...unknownBytes];
    const padding = blockSize - (bytes.length % blockSize);

    const paddingBytes = new Array(padding).fill('p');

    const paddedBytes = [...bytes, ...paddingBytes]

    const result = paddedBytes.reduce((resultArray, item, index) => { 
      const chunkIndex = Math.floor(index / blockSize)
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }
      resultArray[chunkIndex].push(item)
      return resultArray
    }, [])

    return result
  }

  function modifyInput(remove?: boolean) {
    if (remove) {
      if (input > 0) {
        setInput(input - 1);
      }
    } else {
      setInput(input + 1);
    }
  }


  return (
    <S.Wrapper>
      <button onClick={() => {modifyInput()}}>add prepended input bytes</button>
      <button onClick={() => {modifyInput(true)}}>remove prepended input bytes</button>
      <S.Blocks>
        {getBytes().map((x, i) => {
          return <S.Block $index={i} key={i}>
            {x.map((y, j) => {
              return <S.Byte $type={y} $index={i * blockSize + j} key={j}>
                {y}
              </S.Byte>
            })}
          </S.Block>
        })}
      </S.Blocks>
      <S.Legend>
        <p>legend:</p>
        <dl>
          <dt>u</dt>
          <dd>the 138 byte unknown string which is appended to our plaintext input</dd>
          <dt>p</dt>
          <dd>pkcs padding</dd>
          <dt>1</dt>
          <dd>the input we control</dd>
        </dl>
      </S.Legend>
    </S.Wrapper>
  )
}
